import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { AdsGETApi, AdsGETApiAuth, sideBarImage } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import SideBar from '../../SideBar';

export default function AllBooksView(props) {
  const { navigation } = props;
  // const { type, title } = props.route.params;
  const [AllExamList1, setAllExamList1] = React.useState([]);
  const [AllExamList, setAllExamList] = React.useState([]);
  const [reload, setReload] = React.useState(true)
  const LoginState = useSelector((state) => state.LoginState);
  const [usersData, setUsersData] = React.useState({ meta: { number_of_exams: "0" } });
  const [AllExamListUnavailable, setAllExamListUnavailable] = React.useState([]);
  const [AllExamListUnavailable1, setAllExamListUnavailable1] = React.useState([]);
  const [VerifiedvExams, setVerifiedExam] = React.useState([]);
  const [FreeBooks, setFreeBooks] = React.useState([]);
  const location = useLocation()
  var type = location.pathname.slice(slugs.books.length + 1) == "" ? "ubt" : location.pathname.slice(slugs.all_exam.length + 1)

  const groups = {
    "basic-tools": "Basic Tools",
    "topic-meaning": "Topic Meaning",
    "important-meaning": "Important Meaning",
    "grammar": "Grammer",
    "chapter-meaning": "Chapter Meaning",
    "text-book": "Text Book",
    "modal-question": "Modal Question"
  }

  const fetchExam = (perPage, verifiedBookList) => {
    setReload(true)
    const date = new Date()
    AdsGETApiAuth({
      ...perPage,
    }, slugs.db_slug_book)
      .then((res) => {
        setReload(false)
        // setAllExamList1(res.data)
        var verified = [];
        var unverified = [];
        var free = [];
        const freeBooks = res.data.filter(a => a.meta.free == "Free")
        const bookList = res.data.filter(a => a.meta.free !== "Free");
        setFreeBooks(freeBooks)
        for (let i = 0; i < bookList.length; i++) {
          if (verifiedBookList.filter(a => a.book_id == bookList[i].id).length !== 0)
            verified.push(bookList[i])
          else
            unverified.push(bookList[i])
          setAllExamList1(verified)
          setAllExamListUnavailable1(unverified)
        }

      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
      })
  }
  useEffect(() => {
    var type;
    // type = location.pathname.slice(slugs.books.length + 1) == "" ? "basic-tools" : location.pathname.slice(slugs.books.length + 1)
    AdsGETApiAuth({
      status: "any",
      "filter[meta_query][1][key]": "user_id",
      "filter[meta_query][1][value][0]": LoginState.userID,
      "filter[meta_query][1][compare]": "=",
      _fields: "meta"
    }, "user_data")
      .then((response) => {
        if (response.data.length !== 0)
          setUsersData(response.data[0])
        if (response.data[0].meta.verified_book_list == "")
          var verifiedBookList = []
        else
          var verifiedBookList = JSON.parse(response.data[0].meta.verified_book_list)
        setVerifiedExam(verifiedBookList)
        fetchExam({
          per_page: 1000,
          order: "asc",
          _fields: "id,title,meta,slug",
        }, verifiedBookList
        )
      })
      .catch(e => {
        console.log(e.message)
      })
  }, [])

  return (
    <div style={{ backgroundColor: "#eee", minHeight: "90vh" }}>
      <div>
        {!reload &&
          <div className="form-view" style={{ padding: 20, margin: 20 }}>
            {AllExamList1.length !== 0 || FreeBooks.length !== 0 ?
              <div className="row">
                {FreeBooks.map((item, index) => (
                  <div key={index} className="col-sm-3">
                    <Link to={`${slugs.book_details_student}/${item.slug}`}>

                      <div style={{
                        padding: "5px", margin: "5px 0px", border: "1.5px solid #bbb",
                        borderRadius: "5px", color: "#000", textAlign: "center", fontSize: "14px", fontWeight: "bold"
                      }}>
                        {!item.meta.featured_image.includes("https://") ?
                          <img
                            style={{ width: "80%" }}
                            src={sideBarImage}
                          />
                          :
                          <img
                            style={{ width: "80%" }}
                            src={item.meta.featured_image}
                          />
                        }
                        <div>
                          {item.title.rendered}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
                {AllExamList1.map((item, index) => (
                  <div key={index} className="col-sm-3">
                    <Link to={`${slugs.book_details_student}/${item.slug}`}>

                      <div style={{
                        padding: "5px", margin: "5px 0px", border: "1.5px solid #bbb",
                        borderRadius: "5px", color: "#000", textAlign: "center", fontSize: "14px", fontWeight: "bold"
                      }}>
                        {!item.meta.featured_image.includes("https://") ?
                          <img
                            style={{ width: "80%" }}
                            src={sideBarImage}
                          />
                          :
                          <img
                            style={{ width: "80%" }}
                            src={item.meta.featured_image}
                          />
                        }
                        <div>
                          {item.title.rendered}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
              :
              <div>
                No Books access available. Please Contact Admin for book access.
              </div>
            }
          </div>
        }
      </div>
    </div>
  )
}

